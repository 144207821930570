.topbar {
    height: 80px;
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 999;
}

.topbarWrapper {
    height: 100%;
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo {
    height: 50px;
}

.topbarIconContainer {
    position: relative;
    cursor: pointer;
    margin-left: 15px;
}

.topIconBadge {
    width: 15px;
    height: 15px;
    position: absolute;
    top: -5px;
    /* right: 5px; */
    background-color: tomato;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
}

.topRight {
    display: flex;
    align-items: center;
}