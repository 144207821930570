/* .chart{
    margin: 20px;
    padding: 20px;
} */

.chart{
    width: 95%;
    /* display: flex; */
    /* justify-content: space-between; */
    /* padding: 20px; */
    /* margin-right: 2em; */
    /* padding: 20px; */
}