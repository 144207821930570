.sidebar{
    flex: 1;
    height: calc(100vh - 50px);
    position: sticky;
    top: 50px;
    /* background-color: rgb(201, 215, 224); */
}

.sidebarWrapper{
    /* margin-top: 2em;
    padding-left: 1em; */
    padding: 10px 10px;
    color: #555;
}

.sidebarMenu{
    /* background-color: rgb(201, 215, 224); */
    /* margin-bottom: 10px; */
}

.sidebarTitle{
    font-style: 13px;
    /* color: rgb(113, 120, 139); */
}

.nested-list-subheader{
    font-size: 15px;
    color: rgb(133, 133, 133);
    /* background-color: rgb(201, 215, 224); */
    /* margin: 0px; */
}