.featured{
    width: 95%;
    display: flex;
    justify-content: space-between;
    /* padding: 20px; */
    margin: 2em 0;
}

.featuredItem{
    flex: 1;
    margin: 0px 20px;
    padding: 20px;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 1px 0px 15px -6px rgba(0,0,0,0.76);
    -webkit-box-shadow: 1px 0px 15px -6px rgba(0,0,0,0.76);
    -moz-box-shadow: 1px 0px 15px -6px rgba(0,0,0,0.76);
}

.featuredMoneyContainer{
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.featuredMoney{
    font-size: 30px;
    font-weight: 600;
}

.featuredMoneyRate{
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.featuredIcon{
    font-size: 14px;
    margin-left: 5px;
    color: green;
}

.featuredIcon.negative {
    color: tomato;
}